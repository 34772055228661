<template>
  <div>
    <h4 class="mt-3 font-lg">Shipping</h4>
    <div class="border-black58 rounded p-0">
      <div class="d-flex justify-content-between align-items-center py-2 px-3">
        <b>{{shippingAddress.name}}</b>
        <div v-if="userData && shippingAddress.is_primary" class="bg-gold rounded py-1 px-3 text-small text-white">
          Main Address
        </div>
      </div>
      <div class="border-top p-3">
        <p class="mb-0">
          +62{{ shippingAddress.phone }}<br/>
          {{ shippingAddress.address }}, 
          {{ shippingAddress.districts }}, {{ shippingAddress.city }} -
          {{ shippingAddress.regions }} - {{ shippingAddress.postal_code }}
        </p>
      </div>
    </div>
    <button
      v-if="userData"
      id="choose-address"
      type="button"
      class="btn btn-wz-transparent mt-3"
      v-b-modal="'modal-address'"
    >
      Choose Another Address
    </button>
    <button
      v-else
      id="choose-address"
      type="button"
      class="btn btn-wz-transparent mt-3"
      @click="$emit('editAddress')"
    >
      Edit Address
    </button>

    <!--Modal Address-->
    <b-modal
      id="modal-address"
      modal-class="p-0"
      :size="addressList.length > 1 ? 'xl' : null"
      centered
      scrollable
    >
      <template #modal-header>
        <h2 class="title-address text-lg-center">
          Select Shipping Address
        </h2>
        <button class="btn-close-modal-custom" @click="$bvModal.hide('modal-address')">&times;</button>
      </template>
      <div class="row justify-content-center px-3">
        <div
          class="p-1"
          :class="addressList.length > 1 ? 'col-md-6' : 'col'"
          v-for="(address, index) in addressList"
          :key="index"
        >
          <div class="border-black58 rounded p-0">
            <div class="d-flex justify-content-between align-items-center py-2 px-3">
              <b>{{address.name}}</b>
              <button
                v-if="address.id == shippingAddress.id"
                type="button"
                class="main-tag float-right border p-1 w-auto"
              >
                Selected <span class="fa fa-check"></span>
              </button>
              <button
                v-else
                type="button"
                class="main-tag float-right border p-1 w-auto"
                @click="
                  {
                    (shippingAddress = address),
                      $emit('changeAddress', address);
                  }
                "
              >
                Use this address
              </button>
            </div>
            <div class="border-top pt-3 px-3 pb-1">
              <p class="mb-0">
                +62{{ address.phone }}<br/>
                {{ address.address }}, 
                {{ address.districts }}, {{ address.city }} -
                {{ address.regions }} - {{ address.postal_code }}
              </p>
            </div>
            <div class="d-flex align-items-center justify-content-end pb-1">
              <div
                v-b-modal.edit-address
                @click="editAddress = address"
                class="mr-2 cursor-pointer"
              >
                <img src="@/assets/img/edit.png" width="24" height="24" alt="" />
                Edit
              </div>
              <div
                v-if="address.id != shippingAddress.id"
                v-b-modal.delete-address
                @click="editAddress = address"
                class="mr-2 cursor-pointer"
              >
                <img
                  src="@/assets/img/delete-icon.svg"
                  width="16"
                  height="16"
                  alt=""
                />
                Delete
              </div>
            </div>
          </div>
        </div>
      </div>
      <template #modal-footer>
        <button
          class="btn btn-wz-proceed btn-block mt-3"
          v-b-modal.add-address
          @click="$bvModal.hide('modal-address')"
        >
          Add New Address
        </button>
      </template>
    </b-modal>

    <!--Modal Tambah Address-->
    <b-modal
      size="xl"
      id="add-address"
      modal-class="p-0"
      centered
      hide-header
      hide-footer
    >
      <SaveAddress :hasMainAddress="true" :text-right="true" @saveAddressResponse="saveAddress" />
    </b-modal>
    <b-modal
      size="xl"
      id="edit-address"
      modal-class="p-0"
      centered
      hide-header
      hide-footer
    >
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
        @click="$bvModal.hide('edit-address')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
      <SaveAddress
        @saveAddressResponse="saveAddress"
        v-if="editAddress"
        :parentData="editAddress"
        :textRight="true"
        :hasMainAddress="!editAddress.is_primary"
      />
    </b-modal>
    <b-modal
      id="delete-address"
      modal-class="p-0"
      centered
      hide-header
      @ok="deleteAddress(editAddress)"
    >
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
        @click="$bvModal.hide('delete-address')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
      Are you sure you want to delete this address?
    </b-modal>
  </div>
</template>

<script>
import { toaster } from "@/_helpers";
import { addressService } from "@/_services";
import SaveAddress from "@/components/addresses/SaveAddress";

export default {
  props: [
    "addressList",
    "parentData",
  ],
  components: {
    SaveAddress,
  },
  data() {
    return {
      shippingAddress: this.parentData,
      editAddress: null,
    };
  },
  computed: {
    apiUrl() {
      return process.env.VUE_APP_API_URL;
    },
    apiKey() {
      return process.env.VUE_APP_API_KEY;
    },
    userData() {
      return this.$store.getters.getUserData;
    }
  },
  methods: {
    saveAddress() {
      this.$bvModal.hide("add-address");
      this.$bvModal.hide("edit-address");
      this.$bvModal.hide("modal-address");
      this.getAvailableAddressData()
    },
    async deleteAddress(address) { 
      try {
        const res = await addressService.deleteAddress(address.id)
        if (res.status === 200) {
          this.getAvailableAddressData()
          toaster.make("You've successfully removed an address", "success")
        } 
      } catch (e) {
        console.error(e)
      }
    },
    async getAvailableAddressData() {
      this.$emit('refreshAddresses')
      // try {
      //   const res = await addressService.getAvailableAddress()
      //   if (res.status === 200) {
      //     this.addressList = res.data.data;
      //   }
      // } catch (e) {
      //   console.error(e)
      // }
    },
  },
  watch: {
    parentData(newVal) {
      this.shippingAddress = newVal
    }
  },
}
</script>
