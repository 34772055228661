<template>
  <div>
    <h2 class="last-step mb-4">Thank you! Your order has been received</h2>
    <p class="mb-5">
      Confirmation letter has been sent to <strong>Email</strong>
    </p>

    <!-- <h5 class="mb-4">Hello, Don John.</h5> -->

    <p>
      Your order has been successfully completed and will be delivered to you in
      the near future. You can track the delivery status in the Personal
      Account.
    </p>

    <ul class="d-flex align-items-center list-unstyled mt-5">
      <li>
        <a href="/" type="button" class="btn btn-wz-outline mt-0 mr-3"
          >back to store</a
        >
      </li>
    </ul>
  </div>
</template>

<script>
export default {};
</script>

