<template>
  <div>
    <h2 class="mb-5">How would you like to pay?</h2>
    <div class="form-group checkout-page">
      <div id="payment-method" class="payment-method-box mb-5">
        <template v-for="(payment, i) in getListPayment()">
          <template v-if="payment.child">
            <div class="styled-radio" :key="i">
              <label class="container align-items-center clearfix">
                <div class="float-left">
                  <input
                    type="radio"
                    name="parent-radio"
                    @click="openGroup(i + 1, $event)"
                  />
                  <span
                    :id="'radio-' + (i + 1)"
                    class="rounded checkmark"
                    style="top: 3px"
                  ></span>
                  <p class="m-0">
                    <b>{{ payment.name }}</b>
                  </p>
                </div>
                <img
                  class="float-right pt-1 mw-100"
                  v-if="payment.image_url"
                  :src="payment.image_url"
                  alt=""
                />
              </label>
            </div>
            <div
              :class="'midtrans-child-' + (i + 1)"
              class="d-none"
              :key="'midtrans-' + i"
            >
              <template v-for="(bank, j) in payment.child">
                <div class="styled-radio pl-3" :key="'bank-' + j">
                  <label class="container align-items-center clearfix">
                    <div class="float-left">
                      <input type="radio" @click="openChild(i + 1, j + 1)" />
                      <span
                        :id="'child-radio-' + (i + 1) + '-' + (j + 1)"
                        class="position-absolute fa fa-caret-down fa-lg"
                        style="top: 3px; left: 4px"
                      ></span>
                      <p class="m-0">
                        <b>{{ bank.name }}</b>
                      </p>
                    </div>
                    <img
                      class="float-right pt-1 mw-100"
                      v-if="bank.image_url"
                      :src="bank.image_url"
                      alt=""
                    />
                  </label>
                </div>
                <div
                  :class="'bank-child-' + (i + 1) + '-' + (j + 1)"
                  class="styled-radio d-none pl-3"
                  v-for="(method, k) in bank.child"
                  :key="'bank-' + j + '-method-' + k"
                >
                  <label
                    class="container d-flex align-items-center clearfix"
                  >
                    <div class="float-left flex-grow-1">
                      <input
                        type="radio"
                        name="groupedMethod"
                        :checked="
                          $store.getters.getPaymentMethod &&
                          $store.getters.getPaymentMethod.method == method.value
                        "
                        @click="
                          setPaymentMethod({
                            id: method.id,
                            method: method.value,
                            fee: method.total_handling_fee,
                            api_integration: method.api_integration
                          });
                          checkGroupOnly(i);
                        "
                      />
                      <span
                        :id="'method-radio-' + (j + 1)"
                        class="checkmark"
                        style="top: 5px !important"
                      ></span>
                      <p class="m-0" style="line-height: 16px">
                        <b>{{ method.name }}</b
                        >
                        <small class="d-block mt-1" v-if="method.handling_fee_value > 0">
                          Handling fee {{ method.handling_fee_type == 2 ? $helpers.format.price(method.handling_fee_value) : `${method.handling_fee_value}%` }}
                        </small>
                        <small v-html="method.description" class="checkout-payment-method-desc d-block mt-1" v-if="method.description"></small>
                      </p>
                    </div>
                    <img
                      class="float-right pt-1 mw-100"
                      v-if="method.image_url"
                      :src="method.image_url"
                      alt=""
                    />
                  </label>
                </div>
              </template>
            </div>
          </template>
          <div class="styled-radio" v-else :key="i">
            <label
              class="container d-flex align-items-center clearfix"
            >
              <div class="float-left flex-grow-1">
                <input
                  type="radio"
                  name="radio"
                  :checked="
                    $store.getters.getPaymentMethod &&
                    $store.getters.getPaymentMethod.method == payment.value
                  "
                  @click="
                    setPaymentMethod({
                      id: payment.id,
                      method: payment.value,
                      fee: payment.total_handling_fee,
                      api_integration: payment.api_integration
                    });
                    uncheckGroup();
                  "
                />
                <span class="checkmark" style="top: 5px !important"></span>
                <p class="m-0" style="line-height: 16px">
                  <b>{{ payment.name }}</b>
                  <small class="d-block mt-1" v-if="payment.handling_fee_value > 0">
                    Handling fee {{ payment.handling_fee_type == 2 ? $helpers.format.price(payment.handling_fee_value) : `${payment.handling_fee_value}%` }}
                  </small>
                  <!-- <small v-else>No handling fee</small> -->
                  <small v-html="payment.description" class="checkout-payment-method-desc d-block mt-1" v-if="payment.description"></small>
                </p>
              </div>
              <div class="payment-method-img-container float-right" v-if="payment.image_url">
                <b-img :src="payment.image_url" alt="" />
              </div>
              <!-- <img
                class="float-right pt-1 mw-100"
                v-if="payment.image_url"
                :src="payment.image_url"
              /> -->
            </label>
          </div>
        </template>
      </div>
      <div id="use-lizpoints" class="styled-checkbox mb-2" v-if="this.lizpoint_used">
        <label class="container-checkbox">
          Use Points ({{ formatPrice(this.lizpoint_used) }})
          <input
            type="checkbox"
            :checked="$store.getters.getUseLizpoint"
            @click="$store.dispatch('setUseLizpoint')"
          />
          <span class="checkmark"></span>
        </label>
      </div>
      <div
        id="accept-tc"
        class="styled-checkbox mb-2"
        :class="!this.lizpoint_used ? 'mt-5' : ''"
      >
        <label class="container-checkbox">
          I’ve read and accept the
          <!-- <a href="#" class="tnc-url" v-b-modal="'modal-tnc'"
            >terms &amp; conditions</a
          > -->
          <router-link to="/static/terms-conditions" class="tnc-url" target="_blank">terms &amp; conditions</router-link>
          <input
            type="checkbox"
            :checked="$store.getters.getAgreeStatus"
            @click="$store.dispatch('setAgree')"
          />
          <span class="checkmark"></span>
        </label>
      </div>
      <div class="styled-checkbox mb-2" v-if="hasLM">
        <label class="container-checkbox">
          Saya bersedia untuk memberikan identitas diri berupa KTP apabila diminta untuk pembelian logam mulia sesuai dengan peraturan pemerintah
          <input
            type="checkbox"
            :checked="$store.getters.getAgreeLMStatus"
            @click="$store.dispatch('setAgreeLM')"
          />
          <span class="checkmark mt-2"></span>
        </label>
      </div>
      <template v-if="$store.getters.getAgreeLMStatus && hasLM">
        <div class="fw-500">Masukan data berikut sesuai KTP kamu:</div>
        <MyInputFormText
          id="nikNumber"
          :maxlength="16"          
          label="NIK"
          v-model="$v.nikNumber.$model"
          :classComponent="{
            'is-invalid-custom': $v.nikNumber.$error,
            'is-valid-custom': !$v.nikNumber.$invalid,
          }"
          :invalidMessage="[
            !$v.nikNumber.required ? 'Butuh NIK kamu nih' : null,
            !$v.nikNumber.numeric && $v.nikNumber.required ? 'Masukan nomor yu!' : null,
            !$v.nikNumber.minLength ? `NIK kamu terlalu pendek nih, panjang teks minimum ${$v.nikNumber.$params.minLength.min}` : null,
          ]"
          redAsterisk="true"
        />
        <MyInputFormText
          id="nikName"
          label="Name"
          v-model="$v.nikName.$model"
          :classComponent="{
            'is-invalid-custom': $v.nikName.$error,
            'is-valid-custom': !$v.nikName.$invalid,
          }"
          :invalidMessage="[
            !$v.nikName.required ? 'Butuh nama kamu nih' : null,
            !$v.nikName.namePattern ? 'Masukin text yu!' : null,
          ]"
          redAsterisk="true"
        />
      </template>
    </div>
    <!-- <b-modal id="modal-tnc" modal-class="p-0" centered hide-header hide-footer>
      <div class="modal-dialog" role="document" style="display: contents">
        <div class="modal-content" style="display: contents">
          <div class="modal-header">
            <div class="title-address">Terms &amp; Conditions</div>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              @click="$bvModal.hide('modal-tnc')"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body text-justify" style="font-size: 12px">
            <b style="font-size: 10.5px"
              >DENGAN MENGGUNAKAN SITUS, MAKA ANDA SETUJU UNTUK TERIKAT OLEH
              SYARAT DAN KONDISI</b
            ><br /><br />
            <p>
              Terms Conditions, Syarat dan Ketentuan ini mengatur penggunaan dan
              setiap pembelian dari WhizLiz site, dan merupakan perjanjian
              antara Anda dan WhizLiz. WhizLiz berhak untuk mengubah atau
              memodifikasi Syarat dan Ketentuan atau kebijakan atau pedoman dari
              situs setiap saat dan dalam kebijakannya sendiri. Setiap perubahan
              atau modifikasi pada situs akan segera berlaku setelah posting
              dari revisi tersebut. Maka, Anda sebaiknya sering meninjau Syarat
              dan Ketentuan ini dan setiap kebijakan lain yang berlaku dari
              waktu ke waktu.
            </p>
            <p>
              Jika Anda tertarik dengan suatu perhiasan yang saat ini sedang
              tidak tersedia atau out of stock, Anda dapat hubungi kami dan kami
              akan memberitahu ketika barang akan kembali ke stock. Jika
              perhiasan tersebut akan diproduksi kembali, kami akan hubungi Anda
              kembali secara langsung.
            </p>
            <p>
              Data, termasuk harga, mungkin tidak akurat ditampilkan di situs
              kami karena system maupun kesalahan ketik. Sementara kami akan
              melakukan segala upaya untuk menghindari kesalahan ini.
              <b>Whizliz.com</b> berhak untuk memperbaiki setiap kesalahan yang
              terjadi. Kami tidak akan menganggap harga yang salah menjadi
              benar. Jika harga yang tercantum pada produk lebih rendah dari
              harga sebenarnya, kami akan menghubungi Anda mengenai konfirmasi
              harga yang sebenarnya sebelum pengiriman produk atau membatalkan
              pesanan dan memberitahu Anda tentang pembatalan tersebut. Jika
              pesanan telah dikirimkan, Anda setuju untuk membayar selisih
              biaya. Harga kami pun dapat berubah tanpa pemberitahuan. Kami
              mohon maaf atas ketidaknyamanan yang mungkin ditimbulkan. Jika
              Anda memiliki pertanyaan, silakan langsung hubungi salah satu
              Konsultan Diamond dan Perhiasan kami di cs@whizliz.com atau
              +62-818-211863.
            </p>
            <p>
              Ketika Anda membeli perhiasan kami, kami akan melampirkan laporan
              mengenai grading diamond dan informasi mengenai kadar dan warna
              emas. Untuk Diamond yang lebih dari 0.5 Carat (ct.), kami sediakan
              sertifikat <b>GIA</b>, dan IGI.
            </p>
            <p>
              Laporan tersebut adalah yang kami sebut dengan sertifikat berisi
              rincian lengkap. Oleh karena itu, jika Anda tidak mencantumkan
              laporan ini bersama barang yang ingin Anda return/refund/jual akan
              sulit kami terima.
            </p>
            <p>
              Di Whizliz, kami selalu mengupayakan setiap usaha untuk memastikan
              bahwa katalog online kami adalah seakurat dan selengkap mungkin.
              Dalam rangka memberikan Anda kesempatan untuk melihat produk kami
              secara rinci sehingga Anda dapat melihat keindahan detail dan
              bentuk perhiasan kami.
            </p>
            <p>
              Website kami dan semua konten, termasuk logo WhizLiz, semua
              desain, teks, grafik, gambar, informasi, data, software, file
              suara, video, file lainnya adalah eksklusif milik Whizliz atau
              pemberi lisensinya atau penggunanya dilindungi oleh pasar, hak
              cipta, hak paten dan hukum merk dagang, serta berbagai hak
              kekayaan intelektual lainnya dan persaingan tidak sehat hukum.
            </p>
            <p>
              Jika Anda memiliki pertanyaan, kami mengundang Anda untuk
              menghubungi salah satu Customer Service kami setiap hari
              <b>Senin - Jumat (07.00-16.00)</b> melalui telepon ke
              +62-818-211863 atau email kami ke cs@whizliz.com. Semua pengiriman
              barang hanya diproses pada hari kerja. Untuk respon yang lebih
              cepat hubungi kami melalui Whatsapp. Syarat dan Ketentuan
              diperbaharui pada.
            </p>
            <p>
              Terms Conditions, Setiap kali Anda telah selesai melakukan
              pembayaran, segera isi kolom Confirmation Payment dan mengunggah
              bukti transfer atau mengirimkan buktinya pada CS Whizliz. Khusus
              pembelian diatas IDR 25.000.000 Anda wajib mengirimkan foto KTP
              kepada CS kami untuk alasan keamanan dan pesanan tidak akan
              diproses sebelum CS menerima foto KTP Anda.
            </p>
          </div>
        </div>
      </div>
    </b-modal> -->
  </div>
</template>

<script>
import MyInputFormText from "@/components/form/MyInputFormText";
import { regex } from "@/_helpers";
import { requiredIf, numeric, helpers, minLength } from "vuelidate/lib/validators"

// Regex for Validation Start
const namePattern = helpers.regex("namePattern", regex.namePattern);
// Regex for Validation End

export default {
  name: 'PaymentMethod',
  components: {
    MyInputFormText,
  },
  props: ['lizpoint_used', 'hasLM'],
  data() {
    return {
      hasParent: false,
      nikName: this.$store.getters.getUserData?.nik_name ?? "",
      nikNumber: this.$store.getters.getUserData?.nik_number ?? ""
    };
  },
  computed: {
    isKtpFormValid() {
      return !this.$v.nikName.$invalid && !this.$v.nikNumber.$invalid
    }
  },
  validations() {
    return {
      nikNumber: {
        required : requiredIf(() => this.hasLM && this.$store.getters.getAgreeLMStatus),
        numeric,
        minLength: minLength(16),
      },
      nikName: {
        required : requiredIf(() => this.hasLM && this.$store.getters.getAgreeLMStatus),
        namePattern,
      },
    }
  },
  watch: {
    "$store.getters.getListPayment"(newValue) {
      if (this.$store.getters.getPaymentMethod)
        this.updateHandlingFee(newValue);
    },
    nikName: {
      handler(val) {
        this.$emit("setNikName", val)
      },
      immediate: true
    },
    nikNumber: {
      handler(val) {
        this.$emit("setNikNumber", val)
      },
      immediate: true
    },
    isKtpFormValid(val) {
      this.$emit("isKtpFormValid", val)
    }
  },
  methods: {
    getListPayment() {
      let data = this.$store.getters.getListPayment;
      data = data.filter(
        (el) =>
          !el.isDeleted &&
          el.status &&
          (el.device_type == 0 || el.device_type == 1)
      );
      return data;
    },
    setPaymentMethod(value) {
      this.$store.dispatch("setPaymentMethod", value);
    },
    updateHandlingFee(listPayment) {
      for (let i = 0; i < listPayment.length; i++) {
        let element = listPayment[i];
        if (this.$store.getters.getPaymentMethod.method == element.value) {
          return this.setPaymentMethod({
            id: element.id,
            method: element.value,
            fee: element.total_handling_fee,
            api_integration: element.api_integration
          });
        }
        if (element.child) {
          this.updateHandlingFee(element.child);
        }
      }
    },
    uncheckGroup() {
      let child = document.getElementsByName("parent-radio");
      for (let i = 0; i < child.length; i++) {
        child[i].checked = false;
      }
      child = document.getElementsByName("groupedMethod");
      for (let i = 0; i < child.length; i++) {
        child[i].checked = false;
      }
      this.hasParent = false;
    },
    checkGroupOnly(index) {
      let child = document.getElementsByName("radio");
      for (let i = 0; i < child.length; i++) {
        child[i].checked = false;
      }
      child = document.getElementsByName("parent-radio");
      for (let i = 0; i < child.length; i++) {
        if (i == index) {
          child[i].checked = true;
        } else {
          child[i].checked = false;
        }
      }
      this.hasParent = true;
    },
    openGroup(index, accessor) {
      const child = document.getElementsByClassName("midtrans-child-" + index);
      for (let i = 0; i < child.length; i++) {
        child[i].classList.toggle("d-none");
      }
      accessor.target.checked = this.hasParent;
    },
    openChild(index1, index2) {
      document
        .getElementById("child-radio-" + index1 + "-" + index2)
        .classList.toggle("fa-caret-down");
      document
        .getElementById("child-radio-" + index1 + "-" + index2)
        .classList.toggle("fa-caret-up");
      const child = document.getElementsByClassName(
        "bank-child-" + index1 + "-" + index2
      );
      for (let i = 0; i < child.length; i++) {
        child[i].classList.toggle("d-none");
      }
    },
    touchKtpForm() {
      this.$v.$touch();
    },
    formatPrice(value) {
      return value ? value.toLocaleString("id") : value;
    },
  },
};
</script>

<style scoped>
a.tnc-url:hover {
  text-decoration: underline;
}
.fw-500 {
  font-weight: 500;
}
.checkout-payment-method-desc >>> p:last-child {
  margin-bottom: 0;
}
</style>