<template>
  <body id="top">
    <div class="container position-relative">
      <div class="chek-header">
        <router-link to="/" class="text-black d-flex">
          <i class="icon-prev pr-2 bold" style="font-size: larger"></i>
          <img src="@/assets/img/logo-black.svg" alt="" />
        </router-link>
      </div>
    <div class="row box-wizard">
        <div class="col-md-7">
          <!-- // TODO dynamic announcement banner -->
          <UrlHandler v-if="announcementBanner" class="d-lg-none" :url="announcementBanner.url">
            <b-img-lazy :src="announcementBanner.image_mobile_url" :title="announcementBanner.name" :alt="announcementBanner.name" fluid />
          </UrlHandler>
          
          <h4 class="mt-3 font-lg">Item</h4>
          <table>
            <tbody>
              <div
                v-for="(sc, i) in sortedCart"
                :key="i"
                style="display: contents"
              >
                <tr>
                  <td colspan="4">
                    <div class="merchant-name text-gold">
                      <b>{{ sc.seller_name }}</b>
                    </div>
                  </td>
                </tr>
                <tr
                  :class="{ 'border-bottom': j < sc.products.length - 1 }"
                  v-for="(product, j) in sc.products"
                  :key="j"
                >
                  <td colspan="2" class="py-1">
                    <div class="cart-item ml-0 mb-1">
                      <div class="product-thumb p-0">
                        <img
                          :src="
                            product.product_detail.product_images[0]
                              .medium_image_url
                          "
                          alt=""
                          @error="replaceByDefaultImage"
                        />
                      </div>
                      <div class="product-info w-100">
                        <div class="product-brand">
                          <router-link :to="`/product/${product.product_detail.id}`" class="text-black">
                            {{ product.product_detail.brand_name }}
                          </router-link>
                        </div>
                        <div class="product-name">
                          <router-link :to="`/product/${product.product_detail.id}`" class="text-black">
                            {{ product.product_detail.name }}
                          </router-link>
                          <br />
                          <p
                            class="roboto12"
                            :class="{'m-0': product.product_variance_attribute.color.name.charAt(0) != '-'}"
                          >
                            <template v-if="product.product_variance_attribute.color.name.charAt(0) != '-'">
                              Color: {{ product.product_variance_attribute.color.name }}
                            </template>
                            <template v-if="product.product_variance_attribute.size.international.charAt(0) != '-'">
                            | Size: {{ product.product_variance_attribute.size.international }}
                            </template>
                          </p>
                          <div>{{ `Qty: ${product.quantity}` }}</div>
                        </div>
                        <!-- TODO Store Closed Info add condition for shop closed, set text & badge color -->
                        <b class="d-block d-lg-none text-gold">{{ formatPrice(product.product_detail.price_display) }}</b>
                        <!-- if pre order status exist -->
                        <div class="product-status-badge store-closed-badge" v-if="sc.is_closed">Store Closed</div>
                        <div class="product-status-badge preorder-badge" v-else-if="!product.product_detail.status_stock">Pre-order</div>
                        <div class="preorder-estimated d-flex flex-lg-row flex-column" v-if="product.estimated_time_ready">
                          <div>Estimated Delivery:</div>
                          <b class="ml-lg-1">{{ formatEpochMsToLocalTime(product.estimated_time_ready) }}</b> 
                        </div>
                      </div>
                    </div>
                    <Accordion v-if="product.bundled_product.length" title="Bundle Product Detail" :active="true">
                      <ProductRow :products="product.bundled_product" />
                    </Accordion>
                      <h4 class="m-0" :class="{'mt-2' : product.bundled_product.length}">Notes</h4>
                      <div
                        :id="'seller-' + i + '-product-' + j + '-order-notes'" 
                        class="input-line mx-0 mt-1 mb-2">
                        <input
                          class="p-0"
                          type="text"
                          v-model="product.product_detail.order_notes"
                          placeholder="Product Notes"
                        />
                      </div>
                  </td>
                  <td class="d-none d-lg-block text-nowrap py-1">
                    <div 
                      class="product-price text-right"
                      :class="{'sale-price': (product.product_detail.product_type == 4 && product.product_detail.discount_active_bundle) || product.product_detail.discount_active || product.product_detail.bundle_dynamic_price }"
                    >
                      {{ formatPrice(product.product_detail.price_display) }}
                      <span class="old-price" v-if="(product.product_detail.product_type == 4 && product.product_detail.discount_active_bundle) || product.product_detail.discount_active || product.product_detail.bundle_dynamic_price">
                        {{ formatPrice(product.product_detail.price) }}
                      </span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="4">
                    <div class="row align-items-center">
                      <div class="col-md-6 col">
                        <div class="position-relative">
                          <select
                            class="shipping w-100 form-control bg-gold text-white mb-1"
                            :id="`shipping-option-seller-${i}`"
                            @input="getCalculateShipping($event, sc, i)"
                            :disabled="addressList.length == 0"
                          >
                            <option value="null" selected disabled>
                              {{
                                addressList.length == 0
                                  ? "Add your address first"
                                  : "Select Shipping Option"
                              }}
                            </option>
                            <option
                              v-for="(courier, j) in sc.courier_list"
                              :key="j"
                              :value="j"
                            >
                              {{ courier.name }}
                            </option>
                            <option value="-1">Pick-up Point*</option>
                          </select>
                          <select
                            class="w-100 form-control bg-gold text-white"
                            v-if="shipmentType[i].type == 1"
                            :id="'durasi-' + i"
                            @input="setPickupPoint(i, $event)"
                          >
                            <option
                              value="-1"
                              aria-placeholder="time"
                              selected
                            >
                              Select Address
                            </option>
                            <option
                              v-for="(pp, j) in sc.pickuppoint"
                              :value="pp.id"
                              :key="j"
                            >
                              {{ pp.name }} - {{ pp.duration }} (Work Days)
                            </option>
                          </select>
                          <select class="shipping-data w-100 bg-gold text-white form-control"
                            aria-placeholder="time"
                            :id="'durasi-' + i"
                            v-else-if="shippingData[i].courier_code && shipmentType[i].type == 0"
                            @input="setShippingMethod(i, $event)"
                          >
                            <option value="-1" selected disabled>
                              Select Service
                            </option>
                            <option
                              v-for="(courierInfo, j) in shippingCostList[i]"
                              :value="j"
                              :key="j"
                            >
                              {{ courierInfo.etd ? courierInfo.etd : 1 }}
                              day(s) -
                              {{ courierInfo.service_code }} - {{ formatPrice(courierInfo.value) }}
                            </option>
                          </select>
                          <span></span>
                        </div>
                      </div>
                      <div class="col-6 pl-0" v-if="shipmentType[i].loaded">
                        <div
                          v-if="shipmentType[i].type == 1"
                          class="text-lg-right pt-2"
                        >
                          <b>{{ cartPickup[i].name }}</b>
                          <p>
                            {{ cartPickup[i].address }}<br />
                            Item available for pick-up:
                            {{ cartPickup[i].duration }} day(s) from order
                            date.
                          </p>
                        </div>
                        <div
                          v-else-if="shipmentType[i].type == 0"
                          class="text-lg-right"
                        >
                          <b>{{ shippingData[i].courier }}</b>
                          <p class="mb-0">
                            {{ shippingData[i].service }} - 
                            {{ formatPrice(shippingData[i].value) }}<br />
                            {{ shippingData[i].etd }} day(s) shipping
                          </p>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="4" class="py-0">
                    <div
                      :id="'map-' + (i + 1)"
                      class="map rounded"
                      :style="{
                        height:
                          shipmentType[i].type == 1 && shipmentType[i].loaded
                            ? '200px'
                            : 0,
                      }"
                    ></div>
                    <small
                      class="d-block text-center"
                      v-if="shipmentType[i].type == 1 && shipmentType[i].loaded"
                    >
                      Operating days:<br />
                      {{ cartPickup[i].operating_day || "Weekdays" }} ({{
                        cartPickup[i].operating_start_time.substring(0, 5)
                      }}
                      WIB -
                      {{ cartPickup[i].operating_end_time.substring(0, 5) }}
                      WIB)
                    </small>
                  </td>
                </tr>
                <tr>
                  <td colspan="4" class="py-0">
                    <hr
                      class="border-black58"
                      v-if="i < sortedCart.length - 1"
                    />
                  </td>
                </tr>
              </div>
            </tbody>
          </table>
        </div>
        <div class="checkout-page col-md-5">
          <!-- // TODO dynamic announcement banner -->
          <UrlHandler v-if="announcementBanner" class="d-none d-lg-block" :url="announcementBanner.url">
            <b-img-lazy :src="announcementBanner.image_url" :title="announcementBanner.name" :alt="announcementBanner.name" fluid />
          </UrlHandler>

          <AvailableAddress
            v-if="
              addressList.length > 0 && checkoutPageNav.addressPage == true
            "
            :addressList="addressList"
            :parentData="shippingAddress"
            @changeAddress="setShippingAddress"
            @refreshAddresses="getAddressList"
            @editAddress="addressList = []"
          />
          <SaveAddress
            v-show="
              addressList.length == 0 && checkoutPageNav.addressPage == true
            "
            @tempAddress="setGuestAddress"
            @saveAddressResponse="getAddressList"
            :noCancel="true"
          />
          <CompletePurchasePage
            v-if="checkoutPageNav.completePurchase == true"
          />
          <PaymentMethod
            ref="paymentMethod"
            v-if="checkoutPageNav.selectpaymentPage == true"
            :lizpoint_used="lizpoint_used"
            :hasLM="hasLM"
            @setNikName="nikName = $event"
            @setNikNumber="nikNumber = $event"
            @isKtpFormValid="isKtpFormValid = $event"
          />
          <h4 class="mt-4 font-lg">Payment Details</h4>
          <div class="table-bottom mt-auto">
            <table>
              <tbody>
                <tr>
                  <td colspan="2">
                    <div class="d-flex">
                      <button
                        id="choose-voucher"
                        type="button"
                        class="btn btn-wz-transparent"
                        :disabled="!$store.getters.getCheckoutData"
                        v-b-modal="'modal-vouchers'"
                      >
                        {{
                          appliedVouchers.length == 0
                            ? "Click here to use coupon codes"
                            : appliedVouchers.length + " voucher(s) applied!"
                        }}
                      </button>
                      <b-tooltip v-if="!$store.getters.getCheckoutData" target="choose-voucher" noninteractive>Please complete product shipping options.</b-tooltip>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="2">
                    <div
                      class="d-flex coupon-used mt-2 justify-content-between"
                      v-if="
                        $store.getters.getCheckoutData &&
                        $store.getters.getCheckoutData.vouchers.filter(
                          (el) =>
                            /reseller/i.test(el.message) &&
                            el.voucher_cashback_amount > 0
                        ).length
                      "
                    >
                      <div class="text-grey">Extra Commission</div>
                      <div class="text-right text-gold">
                        <strong>
                        {{
                          formatPrice(
                            $store.getters.getCheckoutData.vouchers
                              .filter(
                                (el) =>
                                  /reseller/i.test(el.message) &&
                                  el.voucher_cashback_amount > 0
                              )
                              .map((rel) => rel.voucher_cashback_amount)
                              .reduce((a, b) => a + b)
                          )
                        }}
                        </strong>
                      </div>
                    </div>
                    <div
                      class="d-flex coupon-used mt-2 justify-content-between"
                    >
                      <div class="text-grey">Subtotal</div>
                      <div class="text-right">
                        <strong
                          >
                          {{ formatPrice(totalCartItems()) }}
                          <span
                            v-if="
                              $store.getters.getUseLizpoint &&
                              lizpoint_used > 0 &&
                              checkoutPageNav.selectpaymentPage
                            "
                          >
                            - ({{ $helpers.format.price(lizpoint_used, null) }})
                          </span>
                        </strong>
                      </div>
                    </div>
                    <div
                      class="d-flex coupon-used mt-1 justify-content-between"
                      v-if="totalShipping().normal > 0"
                    >
                      <div class="text-grey">Shipping</div>
                      <div class="text-right">
                        <strong>
                          <template
                            v-if="
                              appliedVouchers.filter(
                                (el) => el.message == 'FREE DELIVERY'
                              )[0]
                            "
                          >
                            <strong class="text-tosca">
                              ({{
                                totalShipping().discounted == 0
                                  ? "FREE"
                                  : formatPrice(totalShipping().discounted)
                              }})
                            </strong>
                            <strike>
                              {{ formatPrice(totalShipping().normal) }}
                            </strike>
                          </template>
                          <template v-else>
                            {{ formatPrice(totalShipping().normal) }}
                          </template>
                        </strong>
                      </div>
                    </div>
                    <div
                      class="d-flex coupon-used mt-1 justify-content-between"
                      v-if="
                        $store.getters.getPaymentMethod &&
                        $store.getters.getPaymentMethod.fee
                      "
                    >
                      <div class="text-grey">Handling Fee</div>
                      <div class="text-right">
                        <strong>
                          {{
                            $store.getters.getPaymentMethod
                              ? formatPrice($store.getters.getPaymentMethod.fee)
                              : 0
                          }}
                        </strong>
                      </div>
                    </div>
                    <div
                      class="d-flex coupon-used mt-1 justify-content-between"
                      v-if="
                        $store.getters.getCheckoutData &&
                        $store.getters.getCheckoutData.vouchers.filter(
                          (el) => !/delivery/i.test(el.message)
                        ).length
                      "
                    >
                      <div class="text-grey">Discount</div>
                      <div class="text-right">
                        <strong>
                          -
                          {{
                            formatPrice(
                              $store.getters.getCheckoutData.vouchers
                                .filter((el) => !/delivery/i.test(el.message))
                                .map((rel) => rel.voucher_amount)
                                .reduce((a, b) => a + b)
                            )
                          }}
                        </strong>
                      </div>
                    </div>
                    <div
                      class="d-flex coupon-used mt-1 justify-content-between"
                    >
                      <div>Total</div>
                      <div class="text-right">
                        <strong>{{ formatPrice(totalOrder()) }}</strong>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <label
            class="bold mt-3"
            v-if="
              token &&
              checkoutPageNav.selectpaymentPage &&
              (lizpoint_earned > 0 || reseller_commission > 0)
            "
          >
            Get
            <!-- Lizpoint Earned -->
            <span v-if="lizpoint_earned > 0">
              points up to
              <span class="text-gold">{{ formatPrice(lizpoint_earned) }}</span>
            </span>
            <span v-if="lizpoint_earned > 0 && reseller_commission > 0">
              &amp;
            </span>
            <!-- Commission Earned -->
            <span v-if="reseller_commission > 0">
              commission up to
              <span class="text-gold">
                {{ formatPrice(this.reseller_commission) }}
              </span>
            </span>
            after this transaction!
          </label>
          <template
            v-if="addressList.length > 0 && checkoutPageNav.addressPage == true"
          >
            <!-- Button Proceed Payment masih bisa dipindahin ke line 620 cuman width belum bisa 100 % -->
            <button
              id="proceed-payment"
              type="button"
              @click="nextPage()"
              class="btn btn-wz-proceed mb-2 btn-block"
              :disabled="!$store.getters.getCheckoutData"
            >
              Proceed to Payment
            </button>
          </template>
          <ul class="d-flex align-items-center list-unstyled pb-2">
            <li v-if="checkoutPageNav.selectpaymentPage == true">
              <button
                type="button"
                class="btn btn-wz-outline prev-step mt-0 mr-3"
                @click="previousPage()"
              >
                Back
              </button>
            </li>
            <li id="complete-purchase" v-if="checkoutPageNav.selectpaymentPage == true">
              <button
                type="button"
                @click="checkUserExist"
                class="btn btn-wz-proceed btn-block"
              >
                Complete Purchase
              </button>
            </li>
            <li
              v-if="addressList.length > 0 && checkoutPageNav.addressPage == true"
            ></li>
          </ul>
          <!-- </div> -->
          <!-- </div> -->
        </div>
      </div>
    </div>
    <b-modal
      id="modal-vouchers"
      size="md"
      modal-class="p-0"
      :hide-footer="!appliedVouchers.length"
      centered
      scrollable
    >
      <template #modal-header>
        <div class="d-flex flex-column w-100" v-if="voucherPage == 1">
          <div class="d-flex align-items-center justify-content-between">
            <h2 class="title-address" id="exampleModalLabel">Select Vouchers</h2>
            <font-awesome icon="times" class="mr-2 cursor-pointer" @click="$bvModal.hide('modal-vouchers')" />
          </div>
          <div class="d-flex mb-2">
            <input
              type="text"
              placeholder="Coupon Code"
              class="form-control"
              name=""
              style="
                text-transform: uppercase;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
              "
              v-model="voucher"
            />
            <button
              type="button"
              class="btn-wz-proceed btn"
              style="border-top-left-radius: 0; border-bottom-left-radius: 0"
              @click="applyVoucher(voucher, false)"
            >
              <font-awesome icon="check" />
            </button>
          </div>
        </div>
        <template v-else-if="voucherPage == 2">
          <fawesome-pro variant="far" icon="chevron-left" size="sm" class="align-self-center cursor-pointer pr-3" @click.native="voucherPage = 1" /> 
          <h5 class="modal-title">Detail Voucher</h5>
          <button class="btn-close-modal-custom" @click="$bvModal.hide('modal-vouchers')">&times;</button>
        </template>
      </template>

      <template v-if="voucherPage == 1">
        <div class="row" v-if="!$store.getters.getCheckoutData">
          <div class="col-md-12 text-center">
            <small>Please complete product shipping options.</small>
          </div>
        </div>
        <!-- NEW Voucher UI -->
        <VoucherCard
          :voucher="voucher"
          v-for="(voucher, indexVoucher) in listVouchers"
          :key="indexVoucher"
          :showChevron="true"
          @click.native="appliedVouchers.find((el) => el.voucher_code == voucher.masking) ? deleteVoucher(voucher.masking) : applyVoucher(voucher)"
          :isSelected="appliedVouchers.find((el) => el.voucher_code == voucher.masking) ? true : false"
          :isValid="voucher.is_valid"
          @toDetailVoucher="toDetailVoucher"
        />

        <!-- OLD Voucher UI -->
        <!-- <div class="row px-2 justify-content-center" v-else>
          <div
            class="col-md-12 p-1"
            v-for="(voucher, i) in listVouchers"
            :key="'list-' + i"
          >
            <div
              @click="appliedVouchers.find((el) => el.voucher_code == voucher.masking) ? deleteVoucher(voucher.masking) : applyVoucher(voucher)"
              class="px-2 py-1 rounded cursor-pointer border border-secondary"
              :class="{'text-white': appliedVouchers.find((el) => el.voucher_code == voucher.masking)}"
              :style="appliedVouchers.find((el) => el.voucher_code == voucher.masking) ? {
                'background': '#c3c94b',
                'border': '2px solid #9ba348 !important',
              } : {'background': voucher.is_valid ? '' : '#cecece'}
              "
            >
              <small class="float-right text-right">
                Min. purchase<br class="d-lg-none" />
                <b :class="{'text-gold': !appliedVouchers.find((el) => el.voucher_code == voucher.masking)}">
                  {{ formatPrice(voucher.min_purchase) }}
                </b>
              </small>
              <p class="m-0">{{ voucher.name || voucher.masking }}</p>
              <div class="row no-gutters">
                <p class="col m-0 text-capitalize">
                  {{ voucher.type == 'RESELLER' ? 'Bus. Partner' : voucher.type.toLowerCase() }}
                  <template v-if="voucher.type == 'RESELLER' && voucher.discount"><br />Diskon</template>
                  <b v-if="voucher.discount" :class="{'text-gold': !appliedVouchers.find((el) => el.voucher_code == voucher.masking)}">
                    {{ voucher.discount_type == 1 ? `${formatPrice(voucher.discount)}` : `${voucher.discount}%` }}
                  </b>
                  <template v-if="voucher.type == 'RESELLER' && voucher.cashback"><br />Extra Commission</template>
                  <b v-if="voucher.cashback" :class="{'text-gold': !appliedVouchers.find((el) => el.voucher_code == voucher.masking)}">
                    {{ voucher.cashback_type == 1 ? `${formatPrice(voucher.cashback)}` : `${voucher.cashback}%` }}
                  </b>
                </p>
                <small class="col-auto">{{ voucher.spesific_payment }}</small>
              </div>
              <div class="d-flex justify-content-between align-items-end">
                <div class="d-flex flex-column">
                  <small>Valid until {{ voucher.valid_to }}</small>
                  <small v-if="voucher.start_time && voucher.end_time"
                    >Valid time: {{ `${voucher.start_time.substr(0, 5)} - ${voucher.end_time.substr(0, 5)} WIB` }}
                  </small>
                </div>
                <small v-if="appliedVouchers.find((el) => el.voucher_code == voucher.masking)" class="float-right pr-2">
                  <span class="fa fa-check"></span>
                </small>
              </div>
              <div v-if="voucher.invalid_reason" class="text-center red">
                <small>{{ voucher.invalid_reason }}</small>
              </div>
            </div>
          </div>
        </div> -->
      </template>

      <template v-else-if="voucherPage == 2">
        <VoucherCard class="cursor-auto" :voucher="selectedVoucher" :isLoading="isLoadingVoucher" />
        <b-skeleton-wrapper :loading="isLoadingVoucher" class="mt-3">
          <template #loading>
            <b-skeleton width="100px" />
            <b-skeleton width="150px" class="mb-3" />

            <b-skeleton width="100px" />
            <b-skeleton width="150px" class="mb-3" />

            <b-skeleton width="100px" />
            <b-skeleton width="100%" v-for="index in 3" :key="index" />
          </template>
          <div class="voucher-detail-additional-info" v-if="selectedVoucher">
            <template v-if="selectedVoucher?.device_source">
              <b>Platform</b>
              <div>{{ selectedVoucher.device_source }}</div>
            </template>

            <template v-if="selectedVoucher?.min_pcs_qty > 0">
              <b>Min. Purchase Quantity</b>
              <div>{{ selectedVoucher.min_pcs_qty }}</div>
            </template>

            <template v-if="selectedVoucher?.accepted_payment">
              <b>Metode Pembayaran</b>
              <div>{{ selectedVoucher.accepted_payment }}</div>
            </template>

            <template v-if="selectedVoucher?.description">
              <b>Syarat & Ketentuan</b>
              <div v-html="selectedVoucher.description"></div>
            </template>
          </div>
        </b-skeleton-wrapper>
      </template>

      <!-- <hr v-if="appliedVouchers.length > 0" /> -->
      <!-- OLD DESIGN -> Just Delete the <template #modal-footer> -->
      <template #modal-footer>
        <!-- <div
          class="row align-items-center w-100 mt-1 mb-0"
          v-for="(voucher, i) in appliedVouchers"
          :key="'used-' + i"
        >
          <div
            @click="deleteVoucher(voucher.voucher_code)"
            class="ml-2 text-tosca cursor-pointer pr-2"
          >
            &times;
          </div>
          <div class="voucher-name-applied-status col p-0 text-tosca">
            {{ voucher.voucher_code }} APPLIED!
          </div>
          <div class="bg-white pr-lg-3">
            - IDR
            <template v-if="voucher.message == 'FREE DELIVERY'">
              {{ formatPrice(totalShipping().normal) }}
            </template>
            <template v-else>
              {{ formatPrice(voucher.voucher_amount) }}
            </template>
          </div>
        </div> -->
        <div class="modal-voucher-footer d-flex align-items-center w-100">
          <div>
            <p class="m-0" v-if="calculateAppliedVoucher('voucher_amount') > 0">
              <!-- Amount Voucher Applied<br/> -->
              Yeay! You Save
              <b class="text-gold">{{ formatPrice(calculateAppliedVoucher('voucher_amount')) }}</b>
            </p>
            <p class="m-0" v-if="calculateAppliedVoucher('voucher_cashback_amount') > 0">
              Extra Commission
              <b class="text-gold">{{ formatPrice(calculateAppliedVoucher('voucher_cashback_amount')) }}</b>
            </p>
          </div>
          <div class="ml-auto">
            <button
              type="button"
              class="btn btn-wz-gold"
              :style="[
                appliedVouchers.length > 0 ? {} : { cursor: 'not-allowed' },
              ]"
              :disabled="appliedVouchers.length == 0"
              @click="$bvModal.hide('modal-vouchers')"
            >
              Use Voucher ({{ appliedVouchers.length }})
            </button>
          </div>
        </div>
      </template>
    </b-modal>
    <b-modal id="modal-email-dupe" centered size="sm" hide-header static lazy>
      Kamu sudah terdaftar sebelumnya dengan email ini, apakah kamu ingin melanjutkan?
      <template #modal-footer>
        <div class="d-flex">
          <button type="button" class="btn" @click="$bvModal.hide('modal-email-dupe')">Batal</button>
          <button type="button" class="btn btn-wz-proceed" @click="paymentPurchase">Lanjut</button>
        </div>
      </template>
    </b-modal>
    <VueTour @previousPage="previousPage" :tourName="tourName" :ref="tourName" :steps="steps" :callbacks="callbacks" />
    <ModalCheckoutWarning :unfinishedOrderList="unfinishedOrderList" @proceedPayment="paymentPurchase(false)" />
  </body>
</template>
<script>
import axios from "axios";
import AvailableAddress from "@/components/checkouts/AvailableAddress.vue";
import SaveAddress from "@/components/addresses/SaveAddress.vue";
import PaymentMethod from "@/components/checkouts/PaymentMethod.vue";
import CompletePurchasePage from "@/components/checkouts/CompletePurchase.vue";
const UrlHandler = () => import('@/components/mains/UrlHandler');
const Accordion = () => import('@/components/bundling/Accordion');
const ProductRow = () => import('@/components/bundling/ProductRow');
import VueTour from "@/components/vuetour/VueTour.vue";
const ModalCheckoutWarning = () => import("@/components/modals/ModalCheckoutWarning");
const VoucherCard = () => import("@/components/voucher/VoucherCard");

import { Loader } from "@googlemaps/js-api-loader";

import { toaster, cookieMan, scroll, mobile, tourSettings, formatter } from "@/_helpers";
import { addressService } from "@/_services";

export default {
  name: "Checkout",
  components: {
    AvailableAddress,
    SaveAddress,
    PaymentMethod,
    CompletePurchasePage,
    UrlHandler,
    Accordion,
    ProductRow,
    VueTour,
    ModalCheckoutWarning,
    VoucherCard,
  },
  computed: {
    apiUrl() {
      return process.env.VUE_APP_API_URL;
    },
    apiKey() {
      return process.env.VUE_APP_API_KEY;
    },
    resellerData() {
      return this.$store.getters.getResellerData;
    },
    userData() {
      return this.$store.getters.getUserData;
    },
    isLoggedIn() {
      return this.$store.getters.getIsLoggedIn;
    },
    hasLM() {
      return this.carts.filter(cart => /fine/i.test(cart.product.category3_name)).length > 0
    }
  },
  data() {
    return {
      // orderNote: "",
      firstLoad: [],
      carts: [],
      announcementBanner: null,
      shippingAddress: null,
      sortedCart: [],
      addressList: [],
      shippingData: [],
      shippingPriceList: {},
      checkoutPageNav: {
        addressPage: true,
        selectpaymentPage: null,
        completePurchase: null,
      },
      shippingCostList: [],
      isLoaded: false,
      token: this.$store.getters.getUserToken,
      paymentRedirect_url: null,
      cartPickup: [],
      shipmentType: [],
      voucher: "",
      appliedVouchers: [],
      listVouchers: [],
      lizpoint_earned: 0,
      lizpoint_used: 0,
      reseller_commission: 0,
      sendInvoice: true,

      // Tour guide for checkout
      tourName: 'checkoutTour',
      steps: [],
      callbacks: {
        onPreviousStep: this.onTourPreviousStep,
        onNextStep: this.onTourNextStep,
        onStart: this.onTourStart,
        onStop: this.onTourStop,
      },
      options: tourSettings,

      // Guest checkout
      guestData: null,

      // NIK Name & Number
      nikName: "",
      nikNumber: "",

      // Form validation status for nik & name (for checkout fine gold product)
      isKtpFormValid: true,

      // For modal checkout warning props
      unfinishedOrderList: [],

      //* Modal voucher
      voucherPage: 1,
      isLoadingVoucher: false,
      selectedVoucher: null,
    };
  },
  watch: {
    "$store.getters.getUseLizpoint"() {
      this.checkValidPayment();
    },
    sortedCart(val) {
      //* Start Checkout Tour Guide for Guest
      if (!cookieMan.get(this.tourName).length && val.length > 0 && !this.isLoggedIn) {
        scroll.scrollActive(false)
        this.setCheckoutTourStep();
        this.checkoutTour().startTour(500);
      } 
    },
    shippingAddress(val) {
      if(!this.checkoutTour().isRunning()) return
      this.checkoutTour().setButtonStatus(`#save-address-form`, 'buttonNext', val != null);
    },
    "$store.getters.getPaymentMethod"(val) {
      if (!this.checkoutTour().isRunning()) return;

      //* Enable "Next" Button for "Payment Method" Tour Step
      this.checkoutTour().setButtonStatus(`#payment-method`, 'buttonNext', val != null);
  
      //* Remove "Use Liz-point" Tour Step if available liz-point is 0'
      // if (this.lizpoint_used > 0) return;
      // const removedStepIndex = this.steps.findIndex(el => /use-lizpoints/.test(el.target));
      // if (removedStepIndex != -1) this.steps.splice(removedStepIndex, 1);
    },
  },
  async mounted() {
    this.getMiniBanner('checkout')
    this.autoChooseService()
    this.initData();

    // init analytics
    this.firebaseBeginCheckout(this.carts);
    if (!this.apiUrl.includes('staging'))
      this.facebookBeginCheckout(this.carts);

    // this.$bvModal.show('modal-announcement')
  },
  methods: {
    async autoChooseService() {
      this.sortedCart = []
      this.shipmentType = []
      this.shippingData = []
      if (this.isLoggedIn) await this.getAddressList();
      this.getCart();
    },
    initData() {
      if (this.$store.getters.getUseLizpoint) this.$store.dispatch('setUseLizpoint');
      if (this.$store.getters.getAgreeStatus) this.$store.dispatch('setAgree');
      if (this.$store.getters.getAgreeLMStatus) this.$store.dispatch('setAgreeLM');
      this.$store.dispatch('setPaymentMethod', null);
    },
    async getDetailVoucher(id) {
      try {
        this.isLoadingVoucher = true;
        const res = await this.$api.order.getDetailVoucher(id);
        if (res.status === 200) this.selectedVoucher = res.data.data;
        else this.$helpers.toaster.make(res.data.message, "danger");
        this.isLoadingVoucher = false;
      } catch (error) {
        console.error(error);
      }
    },
    toDetailVoucher(e, voucher) {
      this.voucherPage = 2;
      this.getDetailVoucher(voucher.id);
      e.stopPropagation();
    },
    async paymentPurchase(triggerCheckoutWarning = true) {
      const data = this.$store.getters.getCheckoutData;
      let popToast = false

      if (this.hasLM && this.$store.getters.getAgreeLMStatus === false) {
        toaster.make("Untuk pembelian LM kamu harus bersedia mengirim data diri berupa KTP apabila diminta", "warning");
        popToast = true
      }
      if (this.$store.getters.getAgreeStatus === false) {
        toaster.make("You haven't agreed to our T&C, please confirm.", "warning");
        popToast = true
      }
      if (this.$store.getters.getPaymentMethod == null) {
        toaster.make("Please select a payment method")
        popToast = true
      }
      if (!this.isKtpFormValid) {
        toaster.make("Please fill NIK and Nama Correctly", "danger");
        this.$refs.paymentMethod.touchKtpForm() // Touch KTP Form to show the error message
        popToast = true
      }

      if (!data) {
        toaster.make("Please recheck item availability/shipping & payment method");
        this.previousPage();
        popToast = true
      }

      if (popToast) return

      data.send_invoice = this.sendInvoice;

      // Check if unfinished order exist
      const res = await this.$api.order.getOrderList("checked");
      if(res.status === 200 && res.data.meta.total > 0 && triggerCheckoutWarning) {
        this.unfinishedOrderList = res.data.data
        return this.$bvModal.show("modal-checkout-warning")
      } 
      
      else if (data && this.$store.getters.getPaymentMethod) { 
        data.payment_id = this.$store.getters.getPaymentMethod.id;
        data.payment_method = this.$store.getters.getPaymentMethod.method;
        this.setOrderNotes(data) // set all product notes for checkout data
        data.nik_name = this.nikName
        data.nik_number = this.nikNumber
        data.vouchers = data.vouchers.map(el => ({ voucher_code: el.voucher_code }))
        const form = new FormData();
        form.append("data", JSON.stringify(data));
        this.saveOrder(form, data, this.$store.getters.getPaymentMethod.api_integration);
      }
    },
    async checkUserExist() {
      if (!this.guestData)
        return this.paymentPurchase()
      try {
        const res = await this.$api.user.checkMember(this.guestData.email_quick_checkout)
        if (res.status === 200) {
          const { exist, active } = res.data.data
          exist && active ? this.$bvModal.show('modal-email-dupe') : this.paymentPurchase()
        } 
        else toaster.make(res.data.message, 'danger')
      } catch (e) {
        console.error(e);
      }
    },
    setOrderNotes(data) {
      const orderNotes = []

      // Get all product notes
      this.sortedCart.forEach(sc =>
        sc.products.forEach(product => 
          orderNotes.push({
            id: product.product_detail.id,
            order_notes: product.product_detail.order_notes,
        })
      ))

      // Set all product notes to checkout data
      data.sellers.forEach(seller =>
        seller.items.map(item => 
          orderNotes.forEach(el => {
            if (el.id == item.product_id) item.notes = el.order_notes
          })
      ))
      // console.log("final checkout data", data);
      return data
    },
    async saveOrder(data, checkoutData, api_integration) {
      //* param "api_integration" => payment method api integration
      try {
        const res = await this.$api.checkout.saveOrder(data)
        if (res.status === 200) {
          if(this.checkoutTour().isRunning()) this.checkoutTour().finish();
          this.firebasePurchase(this.carts, checkoutData, !api_integration ? res.data.data.order_number : res.data.notif.orderNumber)
          if (!this.apiUrl.includes('staging')) this.facebookPurchase()
          if (!api_integration) this.$router.replace(`/order-completed/${res.data.data.order_number}`)
          else this.setPaymentGateWay(res.data.data)
        } else toaster.make(res.data.message, 'danger')
      } catch (e) {
        if (e.response) toaster.make(e.response, 'danger')
        console.error(e);
      }
    },
    setPaymentGateWay(data) {
      const url = data.redirect_url || data.redirectUrl;
      location.href = url;
    },
    applyVoucher(masking, isVoucherList = true) {
      let data = this.$store.getters.getCheckoutData;
      if (data) {
        data = {
          sellers: data.sellers.map((seller) => {
            return {
              courier_service_id: null,
              items: seller.items.map((item) => {
                return {
                  bag_id: item.bag_id,
                  product_id: item.product_id,
                  quantity: item.quantity,
                };
              }),
              merchant_id: seller.merchant_id,
              shipping_price: seller.courier.value,
              vendor_id: null,
            };
          }),
          vouchers: [
            {
              voucher_code: masking.masking
                ? masking.masking.toUpperCase()
                : this.voucher.toUpperCase(),
            },
          ],
        };

        if (masking.is_valid === false) return

        const calcUrl = `users/order/calculate_voucher${isVoucherList ? '' : '_v2'}`;
        axios
          .post(this.apiUrl + calcUrl, data, {
            headers: {
              api_key: this.apiKey,
              token: this.token ?? this.$store.getters.getGuestToken,
            },
          })
          .then((response) => {
            const [ voucher ] = response.data.data
            const found = this.appliedVouchers.findIndex((el) => el.message == voucher.message);
            const pickupPoints = this.shipmentType.filter((el) => el.type == 1 && el.loaded);
            this.voucher = "";
            if (
              voucher.message == "FREE DELIVERY" &&
              pickupPoints.length == this.sortedCart.length
            )
              toaster.make(
                voucher.message +
                  " voucher cannot be applied to pick-up point shipments",
                "danger"
              );
            else if (voucher.voucher_amount + voucher.voucher_cashback_amount == 0)
              toaster.make(
                voucher.message + " voucher cannot be applied to your order",
                "danger"
              );
            else if (found == -1) {
              this.appliedVouchers.push(voucher);
              toaster.make(
                "A voucher has been applied to your order",
                "success"
              );
            } else if (found >= 0) {
              toaster.make(voucher.message + " voucher replaced");
              this.appliedVouchers.splice(found, 1, voucher)
            }
            this.checkValidPayment();
          })
          .catch((error) => {
            if (error) toaster.make("No such voucher exists", "danger");
          });
      } else toaster.make("Please complete shipment data first!", "warning");
    },
    deleteVoucher(voucherCode) {
      const index = this.appliedVouchers.findIndex(
        (el) => el.voucher_code == voucherCode
      );
      this.appliedVouchers.splice(index, 1);
      this.checkValidPayment();
    },
    checkValidPayment() {
      const data = {
        shipping_address: this.shippingAddress.id,
        billing_address: this.shippingAddress.id,
        payment_method: "",
        sellers: [],
        vouchers: this.appliedVouchers,
        loyalty: this.$store.getters.getUseLizpoint ? 1 : 0,
      };
      for (let i = 0; i < this.sortedCart.length; i++) {
        const seller = {
          merchant_id: this.sortedCart[i].id,
          vendor_id: null,
          shipment_type: this.shipmentType[i].type,
          items: [],
          courier: this.shippingData[i],
          pickuppoint: this.cartPickup[i],
        };
        for (let j = 0; j < this.sortedCart[i].products.length; j++) {
          const item = {
            code: this.sortedCart[i].code,
            bag_id: this.sortedCart[i].products[j].id,
            product_id: this.sortedCart[i].products[j].product_detail.id,
            product_variant_id:
              this.sortedCart[i].products[j].product_detail_variance_id,
            quantity: this.sortedCart[i].products[j].quantity,
            notes: this.sortedCart[i].products[j].order_notes,
          };
          seller.items.push(item);
        }
        data.sellers.push(seller);
      }
      if (this.guestData) Object.assign(data, this.guestData)
      const countLoaded = this.shipmentType.filter((el) => el.loaded).length;
      if (countLoaded == this.sortedCart.length) {
        this.$api.checkout.getPayments(data)
        .then(res => {
          if (res.status === 200) {
            this.getListVouchers(data);

            if (this.userData)
              this.calculateLoyalty(data);

            if (this.resellerData && this.resellerData.is_active)
              this.calculateCommission(data);

            this.$store.dispatch("setListPayment", res.data.data);
            if (this.$store.getters.getPaymentMethod) {
              const found = this.checkPaymentMethod(this.$store.getters.getListPayment, false);
              if (!found) this.$store.dispatch("setPaymentMethod", undefined);
            }
            this.$store.dispatch("setCheckoutData", data);
          } else toaster.make(res.data.message, 'danger')
        })
        .catch(e => {
          if (e.response) toaster.make(e.response, 'danger')
          console.error(e);
        })
        return true;
      }
      return false;
    },
    checkPaymentMethod(listPayment, isFound) {
      for (let i = 0; i < listPayment.length; i++) {
        let element = listPayment[i];
        if (this.$store.getters.getPaymentMethod.method == element.value) {
          isFound = true;
        }
        if (element.child) {
          const chosee = this.checkPaymentMethod(element.child);
          if (chosee) isFound = chosee;
        }
      }
      return isFound;
    },
    async getCountLizPoint() {
      try {
        const response = await axios.get(
          this.apiUrl + "users/loyaltypoint/countpoint",
          {
            headers: {
              api_key: this.apiKey,
              token: this.token,
              "Content-Type": "application/json",
            },
          }
        );
        return response.data.data;
      } catch (e) {
        console.error(e);
      }
    },
    async calculateLoyalty(data) {
      const url = 'users/' + (this.$store.getters.getUseLizpoint ? 'order/calculate_loyaltypoint' : 'loyaltypoint/calculate')
      const response = await axios.post(this.apiUrl + url, data, {
        headers: {
          api_key: this.apiKey,
          token: this.token,
        },
      });
      const my_lizpoint = await this.getCountLizPoint();
      const max_usage_lizpoint = response.data.data.points_can_be_used;
      this.lizpoint_used =
        my_lizpoint >= max_usage_lizpoint ? max_usage_lizpoint : my_lizpoint;
      this.lizpoint_earned = response.data.data.points_can_be_earned;
    },
    calculateCommission(data) {
      axios
        .post(this.apiUrl + "users/order/calculate_res_commission", data, {
          headers: {
            api_key: this.apiKey,
            token: this.token,
          },
        })
        .then((response) => {
          this.reseller_commission = response.data.data.reseller_commission;
        });
    },
    nextPage() {
      if (!this.$store.getters.getListPayment) this.checkValidPayment();
      if (this.checkoutPageNav.addressPage == true && this.$store.getters.getCheckoutData) {
        this.checkoutPageNav.addressPage = null;
        this.checkoutPageNav.selectpaymentPage = true;
        // if tour is active, run tour next step when click proceed payment
        if(this.checkoutTour().isRunning()) this.checkoutTour().nextStep(100)

      } else if (this.checkoutPageNav.selectpaymentPage == true) {
        this.checkoutPageNav.addressPage = null;
        this.checkoutPageNav.selectpaymentPage = null;
        this.checkoutPageNav.completePurchase = true;
      } else toaster.make("Please recheck your input", "warning");
    },
    previousPage() {
      if (this.checkoutPageNav.selectpaymentPage == true) {
        this.checkoutPageNav.selectpaymentPage = null;
        this.checkoutPageNav.addressPage = true;
        this.$store.dispatch("setPaymentMethod");
        if (this.$store.getters.getUseLizpoint)
          this.$store.dispatch("setUseLizpoint");
      }
    },
    async getMiniBanner(type) {
      try {
        const res = await this.$api.cms.getMiniBanner(type)
        if (res.status === 200) this.announcementBanner = res.data.data
        else toaster.make(res.data.message, 'danger')
      } catch (e) {
        if (e.response) toaster.make(e.response, 'danger')
        console.error(e);
      }
    },
    async getAddressList() {
      try {
        const res = await addressService.getAvailableAddress()
        if (res.status === 200) {
          //* TODO Start Checkout Tour Guide for User that has address
          if (!cookieMan.get(this.tourName).length) {
            scroll.scrollActive(false)
            this.setCheckoutTourStep();
            //* Remove "Save Form Address" Tour Guide Step if User has address list
            if (res.data.meta.total > 0) this.steps.splice(0,1); 
            this.checkoutTour().startTour(500);
          }
          this.setAddressList(res.data.data)
          if (this.firstLoad.length) this.setShippingAddress(res.data.data[0])
        } else this.$helpers.toaster.make(res.data.message, 'danger')
      } catch (e) {
        if (e.response) this.$helpers.toaster.make(e.response, 'danger')
        console.error(e);
      }
    },
    setGuestAddress(data) {
      const { guest } = data
      delete data.guest
      
      this.guestData = guest
      this.addressList = [data]
      this.setShippingAddress(data)
    },
    setAddressList(data) {
      this.addressList = data;
      const filtered = data.filter((el) => {
        return el.is_primary == true;
      });
      this.shippingAddress = filtered[0];

      // Start checkout tour guide after set addressList
      // if(!cookieMan.get(this.tourName).length && !this.$refs[this.tourName].isRunning()) {
      //   // remove save address form tour step if address list NOT null
      //   if(this.addressList.length > 0) {
      //     const stepIndex = this.steps.findIndex((el) => /save-address-form/.test(el.target))
      //     if(stepIndex != null || undefined) {
      //       this.steps.splice(stepIndex, 1)
      //     }
      //   }
      //   // add timeout so next step (after save first new address) & remove step don't run simultaneously
      //   this.$refs[this.tourName].startTour(500)
      // }

      // const currentStep = this.$tours[this.tourName].currentStep

      // if(this.$refs[this.tourName].isRunning() && /save-address-form/.test(this.steps[currentStep].target)) {
      //   // TODO remove save address tour step after save address 
      //   this.$refs[this.tourName].nextStep(100)
        // }
    },
    setShippingAddress(data) {
      this.shippingAddress = data;
      this.shipmentType.map(el => {
        el.type = null
        el.mask = null
        el.loaded = null
        return el
      })
      // const shippings = document.getElementsByClassName("shipping");
      // for (let i = 0; i < shippings.length; i++)
      //   shippings[i].value = -2;
      this.firstLoad = []
      for (let i = 0; i < this.sortedCart.length; i++) {
        if (this.sortedCart[i].courier_list.length)
          this.getCalculateShipping({ target: { value: 0 } }, this.sortedCart[i], i);
      }

      const map = document.getElementsByClassName("map");
      for (let i = 0; i < map.length; i++) map[i].innerHTML = "";
    },
    totalCartItems() {
      return this.carts.reduce((sum, item) => sum + item.product.price_display * item.quantity, 0);
    },
    setPickupPoint(index1, event) {
      this.cartPickup[index1] = this.sortedCart[index1].pickuppoint.find(
        (el) => el.id == event.target.value
      );
      this.cartPickup[index1].operating_start_time = this.cartPickup[index1]
        .operating_start_time
        ? this.cartPickup[index1].operating_start_time
        : "09:00:00";
      this.cartPickup[index1].operating_end_time = this.cartPickup[index1]
        .operating_end_time
        ? this.cartPickup[index1].operating_end_time
        : "17:00:00";
      this.shipmentType[index1].loaded = true;
      this.checkValidPayment();
      const loader = new Loader({
        apiKey: "AIzaSyD4OaFeki2cLk8pqqgnoNum-iHRe7OzWKE",
        version: "weekly",
        libraries: ["places"],
      });
      const mapContainer = document.getElementById("map-" + (index1 + 1));
      loader.load().then(() => {
        // https://stackoverflow.com/a/66557044
        const google = window.google;
        const map = new google.maps.Map(mapContainer, {
          center: {
            lat: this.cartPickup[index1].latitude,
            lng: this.cartPickup[index1].longitude,
          },
          zoom: 18,
          mapTypeId: google.maps.MapTypeId.ROADMAP,
        });
        new google.maps.Marker({
          position: {
            lat: this.cartPickup[index1].latitude,
            lng: this.cartPickup[index1].longitude,
          },
          map,
        });
      });
      //* Set Tour Guide "Durasi" Next Button Status for the first index of shipping option
      if(index1 == 0 && this.steps.length) this.checkoutTour().setButtonStatus(`#durasi-0`, 'buttonNext', event?.target.value > -1);
    },
    async getCalculateShipping(event, cartData, index1) {
      if (!this.shippingAddress) return
      const { value } = event.target;
      // clear shippingData and set shipmentType loaded to false
      this.shipmentType[index1].loaded = false;
      this.shippingData[index1] = {
        courier: null,
        courier_code: null,
        service: null,
        service_code: null,
        value: null,
        etd: null
      }
      if (value == -1) {
        this.shipmentType[index1].type = 1;
        this.$nextTick(() => document.getElementById(`durasi-${index1}`).value = value) // reset value 
      } else if (value === null) {
        this.shipmentType[index1].type = value;
      } else {
        this.shipmentType[index1].type = 0;
        this.shipmentType[index1].loaded = false;
        // const totalWeight = cartData.products.reduce((sum, el) => sum + el.product_detail.weight * el.quantity, 0);
        var totalWeight;
        try {
          await new Promise(resolve => {
            setTimeout(() => {
              totalWeight = cartData.products.reduce((sum, el) => sum + el.product_detail.weight * el.quantity, 0);
              resolve();
            }, 1);
          })
          const res = await this.$api.checkout.calculateShipping(cartData.subdistrict_id, this.shippingAddress.city_id, totalWeight,
            cartData.courier_list[value].code)
          if (res.status === 200) {
            this.shippingData[index1].courier = this.sortedCart[index1].courier_list[value].name;
            this.shippingData[index1].courier_code = this.sortedCart[index1].courier_list[value].code;
            this.setShippingInfo(res.data.data, cartData.id);
            this.getShippingPriceList(cartData.id, index1)
          }
        } catch (e) {
          if (e.response) toaster.make(e.response, 'danger')
          console.error(e);
        }
      }
      //* Set Tour Guide "Shipping Option" Next Button Status for the first index of shipping option
      if(index1 == 0 && this.steps.length) {
        this.checkoutTour().setButtonStatus(`#shipping-option-seller-0`, 'buttonNext', value != "null");
        this.checkoutTour().setButtonStatus(`#durasi-0`, 'buttonNext', this.shippingData[index1]?.service != null);
      }
      this.appliedVouchers = [];
      this.$store.dispatch("setCheckoutData", null);
      this.$nextTick(() => {
        document.getElementById(`shipping-option-seller-${index1}`).value = value
        document.getElementById("map-" + (index1 + 1)).innerHTML = "";
      })
    },
    setShippingInfo(data, sellerId) {
      this.shippingPriceList[sellerId] = data;
    },
    getShippingPriceList(id, index1) {
      let data = this.shippingPriceList[id]?.[0].costs ?? [];
      this.shippingCostList[index1] = data.map((el) => ({
        service: el.description,
        service_code: el.service,
        etd: el.cost[0].etd,
        value: el.cost[0].value,
      }));
      if (!this.firstLoad.includes(index1)) {
        this.firstLoad.push(index1)
        this.setShippingMethod(index1)
      }
    },
    setShippingMethod(index1, data) {
      // console.log('setShippingMethod', index1, data.target.value)
      const value = data?.target.value ?? 0,
        dataShipping = this.shippingCostList[index1]?.[value];
      if (dataShipping) {
        this.shippingData.splice(index1, 1, Object.assign(this.shippingData[index1], {
          value: parseInt(dataShipping.value),
          etd: dataShipping.etd,
          service: dataShipping.service,
          service_code: dataShipping.service_code,
        }))
        this.shipmentType[index1].loaded = true;
        this.$nextTick(() => document.getElementById(`durasi-${index1}`).value = value)
        this.checkValidPayment();
      }
      //* Set Tour Guide "Durasi" Next Button Status for the first index of shipping option
      if(index1 == 0 && this.steps.length) this.checkoutTour().setButtonStatus(`#durasi-0`, 'buttonNext', data?.target.value > -1);
    },
    totalShipping() {
      const total = this.shippingData.reduce((sum, {value}) => sum + parseInt(value ?? 0), 0)
      const shippingVoucher = this.appliedVouchers.find((el) => el.message == "FREE DELIVERY")
      return { 
        normal: total,
        discounted: shippingVoucher
          ? total <= shippingVoucher.voucher_amount ? 0 : total - shippingVoucher.voucher_amount
          : total
      }
    },
    totalOrder() {
      const fee = this.$store.getters.getPaymentMethod
        ? this.$store.getters.getPaymentMethod.fee
        : 0;
      const lizpoint = this.$store.getters.getUseLizpoint
        ? this.lizpoint_used
        : 0;
      const vouchers = this.appliedVouchers.reduce((sum, el) => 
        sum + (!/delivery/i.test(el.message) ? el.voucher_amount : 0)
      , 0)
      return this.totalCartItems() + this.totalShipping().discounted + fee - lizpoint - vouchers;
    },
    async getListVouchers(data) {
      try {
        const res = await this.$api.checkout.getVouchers(data)
        if (res.status === 200) {
          const { data } = res.data
          this.listVouchers = data.reduce((arr, el) => {
            // listDiscountShipping
            if (el.is_valid === true && el.discount_type === 0) arr[0].push(el)
            // listValid
            if (el.is_valid === true && el.discount_type !== 0) arr[1].push(el)
            // listInvalid
            if (el.is_valid === false && el.discount_type !== 0) arr[2].push(el)
            return arr
          }, [[], [], []]).flat();
        } else toaster.make(res.data.message, 'danger')
      } catch (e) {
        if (e.response) toaster.make(e.response, 'danger')
        console.error(e);
      }
    },
    async getCart() {
      this.carts = JSON.parse(localStorage.getItem('checkoutData'))
      
      // list group by seller & group by PO duration
      for (const i in this.carts) {
        const item = this.carts[i],
          found = this.sortedCart.find(el => el.id == item.product.seller.id && this.formatEpochMsToLocalTime(el.estimated_time_ready) == this.formatEpochMsToLocalTime(item.estimated_time_ready)),
          { product } = JSON.parse(JSON.stringify(item))
        product.order_notes = "";
        delete product.seller
        const mappedProduct = {
          id: item.id,
          bundled_product: item.bundled_product ?? [],
          product_detail: product,
          product_detail_variance_id: item.product_detail_variance_id,
          product_variance_attribute: item.product_variance_attribute,
          quantity: item.quantity,
          status: item.status,
          estimated_time_ready: item.estimated_time_ready
        }
        if (found) {
          found.products.push(mappedProduct)
        } else {
          const { seller } = item.product
          seller.seller_name = item.product.seller_name
          seller.estimated_time_ready = item.estimated_time_ready
          seller.products = [mappedProduct]
          seller.pickuppoint = this.sortedCart.find(({id}) => id == seller.id)?.pickuppoint ?? []
          if (!seller.pickuppoint.length) {
            try {
              const res = await this.$api.checkout.getPickupPoint(item.product.id)
              if (res.status === 200) seller.pickuppoint = res.data.data
              else toaster.make(res.data.message, 'danger')
            } catch (e) {
              if (e.response) toaster.make(e.response, 'danger')
              console.error(e);
            }
          }
          this.shippingData.push({
            courier: null,
            courier_code: null,
            service: null,
            service_code: null,
            value: null,
            etd: null
          })
          this.shipmentType.push({ mask: null, type: null, loaded: false })
          this.sortedCart.push(seller)
          if (seller.courier_list.length) {
            this.getCalculateShipping({ target: { value: 0 } }, seller, this.sortedCart.length - 1)
          }
        }
      }
    },
    firebaseBeginCheckout(data) {
      const items = [];
      for (let i = 0; i < data.length; i++) {
        if (data[i].selected == true) {
          items.push({
            item_id: data[i].product.id.toString(),
            item_name: data[i].product.name,
            item_brand: data[i].product.brand_name,
            item_category: data[i].product.category3_name,
            item_variant: data[i].product_detail_variance_id.toString(),
            currency: "IDR",
            price: data[i].product.price_display,
            quantity: data[i].quantity,
          });
        }
      }

      // when user begins the checkout process.
      this.$firebaseLogEvent("begin_checkout", {
        currency: "IDR",
        items: items,
        value: this.totalOrder(),
      });
    },

    firebasePurchase(data, checkoutData, transaction_id) {
      const coupons = checkoutData.vouchers.map(el => el.voucher_code);
      const items = []

      for (let i = 0; i < data.length; i++) {
        if (data[i].selected == true) {
          items.push({
            item_id: data[i].product.id.toString(),
            item_name: data[i].product.name,
            item_brand: data[i].product.brand_name,
            item_category: data[i].product.category3_name,
            item_variant: data[i].product_detail_variance_id.toString(),
            currency: "IDR",
            price: data[i].product.price_display,
            quantity: data[i].quantity,
          });
        }
      }

      // when user click complete purchase
      this.$firebaseLogEvent("purchase", {
        affiliation: "Whizliz Marketplace",
        coupon: coupons.join(","),
        currency: "IDR",
        items: items,
        payment_type: checkoutData.payment_method,
        transaction_id: transaction_id,
        shipping: this.totalShipping().discounted,
        value: this.totalOrder(),
      });
    },

    facebookBeginCheckout(data) {
      this.$analytics.fbq.event("InitiateCheckout", {
        content_type: "product",
        currency: "IDR",
        num_items: data.length,
        value: this.totalOrder(),
      });
    },

    facebookPurchase() {
      this.$analytics.fbq.event("Purchase", {
        value: this.totalOrder(),
        currency: "IDR",
      });
    },
    calculateAppliedVoucher(key) {
     return this.appliedVouchers.reduce((sum, el) => sum + el[key], 0);
    },
    formatPrice(value) {
      // return value ? value.toLocaleString("id") : value;
      return this.$helpers.format.price(value);
    },
    formatEpochMsToLocalTime(value) {
      return formatter.epochMsToLocalTime(value, "DD MMMM YYYY")
    },
    replaceByDefaultImage(img) {
      img.target.src = require("@/assets/img/product-default.png");
    },
    gotoTop() {
      document.getElementById("top").scroll(0, 0);
    },
    checkoutTour() {
      return this.$refs[this.tourName];
    },
    // Vue Tour Methods START
    setCheckoutTourStep() {
      this.steps = [
        {
          target: "#save-address-form",
          header: {
            title: "Save Address"
          },
          content: `Halo, salam kenal! Alamat kamu masih kosong nih, isi dulu yuk biar kamu bisa memulai berbelanja.`,
          params: {
            placement: mobile.isMobile() ? 'top' : 'left-start',
            enableScrolling: false,
            enableButton: Object.assign({}, this.options.enabledButtons, { buttonNext: false })
          }
        },
        {
        target: `#choose-address`,
          header: {
            title: "Address"
          },
          content: `Cek alamat kamu dengan teliti yah! Kamu juga bisa ganti alamat kamu kok, tekan tombol ${this.isLoggedIn ? 'Change' : 'Edit'} Address yah!`,
          params: {
            placement: mobile.isMobile() ? 'bottom' : 'left',
            enableScrolling: false,
            enableButton: Object.assign({}, this.options.enabledButtons, {})
          }
        },
        {
          target: `#shipping-option-seller-0`,
          header: {
            title: "Shipping Option"
          },
          content: `Tekan untuk memilih ekspedisi yang kamu inginkan, dijamin aman!`,
          params: {
            placement: mobile.isMobile() ? 'top' : 'right',
            enableScrolling: false,
            enableButton: Object.assign({}, this.options.enabledButtons, { buttonNext: false })
          }
        },
        {
          target: `#durasi-0`,
          header: {
            title: "Durasi"
          },
          content: `Tekan untuk memilih durasi pengiriman yang kamu inginkan, lebih cepat lebih baik.`,
          params: {
            placement: mobile.isMobile() ? 'top' : 'right',
            enableScrolling: false,
            enableButton: Object.assign({}, this.options.enabledButtons, { buttonNext: false })
          }
        },
        {
          target: `#choose-voucher`,
          header: {
            title: "Choose Voucher"
          },
          content: `Jangan lupa masukin kode vouchernya juga yah, biar dapet diskon!`,
          params: {
            placement:  mobile.isMobile() ? 'top' : 'left',
            enableScrolling: false,
            enableButton: Object.assign({}, this.options.enabledButtons, {})
          }
        },
        {
          target: `#proceed-payment`,
          header: {
            title: "Proceed Payment"
          },
          content: `Setelah kamu selesai mengisi data sebelumnya, klik disini untuk memilih pembayaran`,
          params: {
            placement:  mobile.isMobile() ? 'top' : 'left',
            enableScrolling: false,
            enableButton: Object.assign({}, this.options.enabledButtons, {})
          }
        },
        {
          target: `#payment-method`,
          header: {
            title: "Metode Pembayaran"
          },
          content: `Pilih metode pembayaran kamu disini.`,
          params: {
            placement:  mobile.isMobile() ? 'bottom' : 'left',
            enableScrolling: false,
            enableButton: Object.assign({}, this.options.enabledButtons, { buttonNext: false })
          }
        },
        ...this.isLoggedIn
          ? [{
            target: `#use-lizpoints`,
            header: {
              title: "Penggunaan Liz-Point"
            },
            content: `Liz-Point kamu masih tersisa, gunakan sekarang dengan cara mencentang kolom berikut.`,
            params: {
              placement:  mobile.isMobile() ? 'top' : 'left',
              enableScrolling: true,
              enableButton: Object.assign({}, this.options.enabledButtons, {})
            }
          }]
          : []
        ,
        {
          target: `#accept-tc`,
          header: {
            title: "Terms & Conditions"
          },
          content: `Jangan lupa baca & setujui terms & conditions nya ya.`,
          params: {
            placement:  mobile.isMobile() ? 'top' : 'left',
            enableScrolling: true,
            enableButton: Object.assign({}, this.options.enabledButtons, {})
          }
        },
        {
          target: `#complete-purchase`,
          header: {
            title: "Place Order"
          },
          content: `Langkah terakhir! Tekan place order agar kami segera memproses pesananmu. Happy Shopping :)`,
          params: {
            placement: 'top',
            enableScrolling: true,
            enableButton: Object.assign({}, this.options.enabledButtons, {})
          }
        },
      ]
    },
    onTourStart() {
      const offsetPercentage = mobile.isMobile() ? 10 : 50 

      this.$firebaseLogEvent('begin_tutorial', {
        tutorial_name: "Checkout Tour",
        tutorial_page: "Checkout",
      });

      scroll.scrollTo(this.steps[0].target.split("#")[1], offsetPercentage)
      // scroll.scrollActive(false)
    },
    onTourStop() {
      if (this.checkoutTour().isRunning() == false) return;

      const currentStep = this.$tours[this.tourName].currentStep
      const isSkip = currentStep < this.steps.length - 1

      this.$firebaseLogEvent(isSkip ? 'skip_tutorial' : 'complete_tutorial', {
        tutorial_name: "Checkout Tour",
        tutorial_page: "Checkout",
      });

      scroll.scrollActive(true)
      // If tour guide stopped in checkout page > add cookie checkoutTour
      if (/\/checkout/.test(this.$router.currentRoute.path)) document.cookie = `${this.tourName}=true; expires=Mon, 31 Dec 2040 12:00:00 GMT; path=/`;
    },
    onTourPreviousStep(currentStep) {
      //* Condition to enable scrolling on "Payment Method" Tour Guide
      if(this.steps[currentStep - 1].target == "#payment-method") scroll.scrollActive(true);
      if(this.steps[currentStep].target == "#payment-method") scroll.scrollActive(false);
      if(this.steps[currentStep].target == "#choose-address" && !this.isLoggedIn) {
        this.addressList = [];
        this.shippingAddress = null;
      }
      
      const offsetPercentage = mobile.isMobile() ? 20 : 50 
      
      scroll.scrollTo(this.steps[currentStep - 1].target.split("#")[1], offsetPercentage)
    },
    onTourNextStep(currentStep) {
      //* Condition to enable scrolling on "Payment Method" Tour Guide
      if(this.steps[currentStep + 1].target == "#payment-method") scroll.scrollActive(true);
      if(this.steps[currentStep].target == "#payment-method") scroll.scrollActive(false);

      const offsetPercentage = mobile.isMobile() ? 20 : 50 

      scroll.scrollTo(this.steps[currentStep + 1].target.split("#")[1], offsetPercentage)
    },
    // Vue Tour Methods END
  },
  metaInfo() {
    return {
      title: "Checkout",
    };
  },
};
</script> 
<style scoped>
* {
  transition: 0.2s all;
}
select.form-control {
  appearance: none;
}
select.form-control + span:after {
  background: #d89700;
  content: "\f107";
  font-family: "Font Awesome 5 Pro";
  font-weight: 700;
  position: absolute;
  color: #fff;
  top: 10px;
  right: 1px;
  padding: 0 0.75rem;
}

@media (min-width: 992px) {
  .box-wizard > .col-md-7 {
    min-height: 100vh;
    height: auto;
  }
}
.container {
  max-width: 1568px;
}
/* .voucher-price-applied {
  padding-right: 1rem;
} */
.voucher-name-applied-status {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.product-price {
  padding: 5px 0px;
}

@media only screen and (max-width: 992px) {
  .product-name :is(a,p), .po-status-badge + p, .preorder-estimated {
    font-size: 11px!important;
  }
  /* Hotfix for mobile view in checkout page */
  .box-wizard > * {
    min-height: unset !important;
  }
}

</style>